import React from 'react';
import Slider from 'react-slick';

// Slick settings
const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  autoplay: true,
  autoplaySpeed: 5000, // 5 seconds
};

const Carousel = () => {
  return (
    <div className="flex justify-center mt-20 w-full">
      <div className="relative w-full max-w-full">
        <Slider {...settings}>
          <div className="w-full">
            <img
              src='/images/9.jpg'
              className="w-full h-96 object-cover"
              alt="Slide 1"
            />
          </div>
          <div className="w-full">
            <img
              src='/images/4.jpg'
              className="w-full h-96 object-cover"
              alt="Slide 2"
            />
          </div>
          <div className="w-full">
            <img
              src='/images/3.jpg'
              className="w-full h-96 object-cover"
              alt="Slide 3"
            />
          </div>
          <div className="w-full">
            <img
              src='/images/1.jpg'
              className="w-full h-96 object-cover"
              alt="Slide 4"
            />
          </div>
          <div className="w-full">
            <img
              src='/images/5.jpg'
              className="w-full h-96 object-cover"
              alt="Slide 5"
            />
          </div>
          
          <div className="w-full">
            <img
              src='/images/7.jpg'
              className="w-full h-96 object-cover"
              alt="Slide 7"
            />
          </div>
          <div className="w-full">
            <img
              src='/images/8.jpg'
              className="w-full h-96 object-cover"
              alt="Slide 8"
            />
          </div>
          
        </Slider>
      </div>
    </div>
  );
};

export default Carousel;

import React from 'react';

const PoliciesSection = () => (
  <section id="policies" className="max-w-3xl mx-auto my-8 p-4 bg-white shadow-md">
    <h2 className="border-b-2 border-blue-900 pb-2 mb-4 text-2xl font-bold">Policies</h2>
    <ul className="list-disc list-inside">
      <li>Guests are required to show a photo ID and credit card upon check-in. All special requests are subject to availability and additional charges may apply.</li>
      <li>No smoking</li>
      <li>No pets allowed</li>
      <li>Credit cards accepted: Mastercard and Visa</li>
      <li>Check-in: 2pm to 11pm</li>
      <li>Check-out: before 11am</li>
      <li>One child under 4 years stays free of charge when using existing beds.</li>
      <li>All older children or adults are charged CAD 10 per person per night when using existing beds.</li>
      <li>The maximum number of extra beds in a room is 1. Any type of extra bed is upon request and needs to be confirmed by management. Additional charges will apply.</li>
      <li>There is no capacity for cribs in the room.</li>
      <li>Additional fees are not calculated automatically in the total cost and will have to be paid for separately during your stay.</li>
    </ul>
  </section>
);

export default PoliciesSection;

import React from 'react';

const LocationSection = () => (
  <section id="location" className="max-w-3xl mx-auto my-8 p-4 bg-white shadow-md">
    <h2 className="border-b-2 border-blue-900 pb-2 mb-4 text-2xl font-bold">Location</h2>
    
    <div className="relative pt-[56.25%] mt-4">
      <iframe
        title="Location Map"
        className="absolute inset-0 w-full h-full"
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2889.4591505152787!2d-79.413!3d44.608!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4d2aa1aa68be3c0f%3A0x77b3f20b8e8d5018!2s436%20Laclie%20St%2C%20Orillia%2C%20ON%20L3V%204P6%2C%20Canada!5e0!3m2!1sen!2sus!4v1632544446097!5m2!1sen!2sus"
        allowFullScreen
      />
    </div>
  </section>
);

export default LocationSection;

import React, { useState } from 'react';
import Slider from 'react-slick';

const RoomModal = ({ roomId, images, closeModal }) => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 750,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <div id={`${roomId}-modal`} className="fixed z-50 inset-0 bg-black bg-opacity-70 flex items-center justify-center">
      <div className="bg-white p-6 max-w-2xl w-full">
        <Slider {...settings} >
          {images.map((img, index) => (
            <div key={index}>
              <img src={img} alt={`Room ${roomId} Image ${index + 1}`} className="w-full h-auto object-cover" />
            </div>
          ))}
        </Slider>
        <button className="text-gray-600 text-4xl cursor-pointer mt-6" onClick={closeModal}>&times;</button>
      </div>
    </div>
  );
};

// The Room and RoomsSection components remain the same

const Room = ({ title, rate, roomId, images }) => {
  const [isOpen, setIsOpen] = useState(false);

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  return (
    <div className="bg-gray-200 p-4 rounded-lg w-1/3 text-center shadow-md">
      <h3 className="font-bold mb-2">{title}</h3>
      <p>Starting from ${rate} per night</p>
      <button onClick={openModal} className="bg-blue-900 text-white px-4 py-2 rounded mt-4">View Room</button>
      {isOpen && <RoomModal roomId={roomId} images={images} closeModal={closeModal} />}
    </div>
  );
};

const RoomsSection = () => (
  <section id="rooms" className="max-w-3xl mx-auto my-8 p-4 bg-white shadow-md">
    <h2 className="border-b-2 border-blue-900 pb-2 mb-4 text-2xl font-bold">Our Rooms</h2>
    <div className="flex justify-around mb-4">
      <Room title="One Queen Bed" rate="75" roomId="room2" images={['images/queen/1.jpg', 'images/queen/2.jpg', 'images/queen/3.jpg', 'images/queen/4.jpg', 'images/queen/5.jpg', 'images/queen/6.jpg', 'images/queen/7.jpg', 'images/queen/8.jpg', 'images/queen/9.jpg']} />
      <Room title="Two Double Beds" rate="85" roomId="room1" images={['images/double/1.jpg', 'images/double/2.jpg', 'images/double/3.jpg', 'images/double/4.jpg', 'images/double/5.jpg', 'images/double/6.jpg', 'images/double/7.jpg', 'images/double/8.jpg']} />
      <Room title="Jacuzzi Room" rate="120" roomId="room3" images={['images/jet/1.jpg', 'images/jet/2.jpg', 'images/jet/3.jpg', 'images/jet/4.jpg', 'images/jet/5.jpg',]} />
    </div>
    <p>All rooms contain the following:</p>
    <ul className="list-disc list-inside">
      <li>Microwave</li>
      <li>Mini fridge</li>
      <li>Television & cable</li>
      <li>Free Wi-Fi</li>
      <li>Coffee kettle</li>
      <li>Free parking (1 spot per room) for boats, trucks, and RV</li>
    </ul><br></br>
    <p>Note: Tax rates will apply</p>
  </section>
);

export default RoomsSection;

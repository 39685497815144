import React from 'react';
import Header from './components/Header';
import Carousel from './components/Carousel';
import WelcomeSection from './components/WelcomeSection';
import RoomsSection from './components/RoomsSection';
import PoliciesSection from './components/PoliciesSection';
import LocationSection from './components/LocationSection';
import Footer from './components/Footer';


function App() {
  return (
    <div className="App">
      <script src="../path/to/flowbite/dist/flowbite.min.js"></script>
      <Header />
      <Carousel />
      <WelcomeSection />
      <RoomsSection />
      <PoliciesSection />
      <LocationSection />
      <Footer />

    </div>
  );
}

export default App;

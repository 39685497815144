import React, { useState } from 'react';

const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <section id="home">
      <header className="bg-blue-900 text-white fixed w-full top-0 z-50 shadow-md">
        <div className="flex justify-between items-center p-4">
          {/* Hamburger menu for mobile */}
          <div className="md:hidden">
            <button onClick={toggleMenu} className="text-white focus:outline-none">
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16m-7 6h7"
                />
              </svg>
            </button>
          </div>

          {/* Main navigation */}
          <nav className={`md:flex md:items-center md:space-x-4 ${isMenuOpen ? 'block' : 'hidden'} md:block`}>
            <a href="#rooms" className="block md:inline-block my-2 md:my-0 mx-4">Rooms</a>
            <a href="#policies" className="block md:inline-block my-2 md:my-0 mx-4">Policies</a>
            <a href="#location" className="block md:inline-block my-2 md:my-0 mx-4">Location</a>
          </nav>

          {/* Logo and contact information */}
          <h1 className="text-3xl md:text-4xl font-bold text-center flex-1">
            <a href="#home" className="text-white no-underline">Holiday Motel</a>
          </h1>

          <div className="text-right hidden md:block">
            <p>Email: <span id="email">info@holidaymotelorillia.com</span></p>
            <p>Phone: <span id="phone">1-866-677-4626</span></p>
            <p>Address: <span id="address">436 Laclie Street, Orillia, Ontario</span></p>
          </div>
        </div>

        {/* Mobile contact info */}
        <div className={`px-4 pt-2 pb-4 md:hidden ${isMenuOpen ? 'block' : 'hidden'}`}>
          <p>Email: <span id="email">info@holidaymotelorillia.com</span></p>
          <p>Phone: <span id="phone">1-866-677-4626</span></p>
          <p>Address: <span id="address">436 Laclie Street, Orillia, Ontario</span></p>
        </div>
      </header>
    </section>
  );
};

export default Header;
